@keyframes slideInFromTop {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150%);
  }
}

@keyframes gradientAnimationToBottom {
  from {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("https://64.media.tumblr.com/77c1e8b65077e62ab66fbe8efa62bc85/tumblr_mxpq0pMO941sxqh33o1_400.gifv");
  }
  to {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("https://64.media.tumblr.com/77c1e8b65077e62ab66fbe8efa62bc85/tumblr_mxpq0pMO941sxqh33o1_400.gifv");
  }
}

body,
html {
  height: 100%;
  margin: 0;
  color: white;
  background-color: black;
}

.container-fluid {
  max-width: 100%;
  padding: 20px;
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black; /* Background of the area */
}

.gif {
  position: absolute;
  width: 100%;
  height: 40vw;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://64.media.tumblr.com/77c1e8b65077e62ab66fbe8efa62bc85/tumblr_mxpq0pMO941sxqh33o1_400.gifv");
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  animation: gradientAnimationToBottom 0.5s ease-in-out forwards; /* Animation for the gradient */
  z-index: 0;
}

.gif::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 0;
}

.carousel-indicators {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  width: 70%; /* Width of the button */
  height: 20px; /* Height of the button */
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  border-radius: 50%; /* Rounded buttons */
  width: 50px; /* Width of the button */
  height: 50px; /* Height of the button */
}

carousel-indicators:hover,
.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.img-fluid, .carousel-item img {
  object-fit: cover; /* Crop the image to fit the container */
}


.show-more-button:hover {
  color: rgb(108, 108, 243)!important;
}


.success-message {
  position: relative;
  top: 200px;
  text-align: center;
  color: green;
  z-index: 1;
}

.check-mark {
  font-size: 100px; /* Increased font size */
  margin-bottom: 20px; /* Add some space between the check mark and the text */
}

.success-message p {
  font-size: 30px; /* Increased font size for the text */
  margin-bottom: 30px; /* Add some space between the text and the button */
}

.success-message button {
  font-size: 20px; /* Adjust button font size as needed */
  padding: 10px 20px; /* Adjust button padding as needed */
  /* Add any additional button styling here */
}

.error-message {
  color: red;
}

.formDiv {
  position: relative;
  width: 75%;
  margin: auto;
}

input,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

textarea {
  height: 500px;
}

label {
  color: white;
}

/* Styles for message sent notification */
.send {
  display: none;
  color: green;
}

/* Styles for message error notification */
.send2 {
  display: none;
  color: red;
}

/* Display the message notifications when they are active */
.send.active,
.send2.active {
  display: block;
}

/* Media query for screens below 1150px */
@media (max-width: 1150px) {
  .formDiv {
    width: 100%; /* Increase the width */
  }

  input,
  textarea {
    padding: 8px 15px; /* Adjust padding for smaller screens */
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.father {
  height: 100%;
  position: relative;

}

html,
body {
  height: 100%;
  margin: 0;
  background-color: black;
}

#root,
.App,
.content-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  /* Add bottom padding to ensure space for the footer */
  padding-bottom: 150px; /* Adjust this value to match the footer's height */
  flex: 1; /* This will make it grow to fill available space */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.App {
  position: fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("https://media2.giphy.com/media/xT9IgzoKnwFNmISR8I/giphy.gif?cid=ecf05e47p959r10qtra0v6i6yt1sgbyj5q6fejlatqm24g8z&rid=giphy.gif");
  transition: filter 5s;
  filter: brightness(100%);
}

.App.onload {
  filter: brightness(20%);
  height: 100%;
}

.skills {
  filter: brightness(50%);
  z-index: 15;
  height: 50px;
  left: -1000px;
}

.skills:hover {
  filter: brightness(200%);
  transform: scale(300%);
  animation-play-state: paused;
}


.navbar {
  font-size: 1rem;
  background: transparent;
  color: white;
  text-align: center;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  z-index: 99;
}

.hamburger-icon {
  display: inline-block;
  cursor: pointer;
}

.hamburger-icon span {
  display: block;
  width: 25px; /* or your preferred width */
  height: 3px; /* or your preferred height */
  background-color: whitesmoke; /* or your preferred color */
  margin: 4px 0;
}

.navbar-toggle {
  background: transparent;
  border: none; /* Remove default button styling */
  padding: 0; /* Adjust as needed */
  cursor: pointer; /* Changes cursor to pointer on hover */
  display: none; /* Initially hidden, shown in media query */
}

.navbar-toggle:hover .hamburger-icon span {
  background-color: #e0e0e0; /* Lighter color on hover */
  opacity: 0.8;
  transform: scale(1.1);
}

/* Active style for the hamburger icon */
.navbar-toggle:active .hamburger-icon span {
  background-color: #a0a0a0; /* Darker color when active */
  transform: scale(0.9);
  filter: brightness(75%);
}

.navbar-toggle:hover .navbar-toggler-icon {
  /* Styles for hover and focus */
  /* Example: slightly reduce the opacity */
  opacity: 0.8;
  transform: scale(1.1);
}

.navbar-toggle:active .navbar-toggler-icon {
  /* Styles for active (while clicked) */
  /* Example: slightly darken the image */
  transform: scale(0.9);
  filter: brightness(75%);
}

.navbar-toggler-icon {
  background: transparent;
  background-size: cover;
  width: 90px;
  height: 105px;
  padding-bottom: 15px;
  transition: opacity 0.3s, filter 0.3s; /* Smooth transition for hover and active effects */
}

.navbar-row {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center; /* Vertically center the items in the navbar */
  padding-top: 20px; /* Add padding at the top to lower the nav links */
}

.navbar-item {
  margin-bottom: 2px; /* Adjust as needed */
  z-index:33;
}
.navbar-logo {
  display: none; /* Hide by default */
  position: absolute;
  left: 30px; /* Adjust as needed */
  top: 30px; /* Adjust as needed */
  width: 90px;
  height: 90px;
  width: auto; /* Keep the aspect ratio */
}

@media (min-width: 1150px) {
  .navbar-logo {
    display: block; /* Show only on larger screens */
  }
  .navbar-row {
    height: 120px; /* Increase the height of the navbar */
  }
  .navbar {
    height: auto; /* Allow the navbar to adjust based on content */
    padding-top: 0; /* Remove padding at the top if not needed */
  }
}

span.navbar-text{
  color: white;
  cursor: pointer;
}

span.navbar-text:hover{
  color:rgb(108, 108, 243);
}

span.navbar-text.active{
  border-bottom: 2px solid white; /* White border for the active link */
  padding: 10px; /* Optional: for better visual appearance */
}

span.navbar-text.active:hover{
  border-bottom: 2px solid rgb(108, 108, 243); /* White border for the active link */
}



/* Media query for screens below 1150px */
@media (max-width: 1150px) {
  .navbar-toggle {
    display: block;
    position: absolute;
    top: 30px; /* Adjust as needed */
    left: 30px; /* Adjust as needed */
    z-index: 2147483647; /* Higher z-index */
  }

  .navbar-row {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black; /* Your desired background */
    width: 100%;
    height: 102vh;
    z-index: 2147483646; /* Higher z-index */
  }

  .navbar-item {
    text-align: center;
    width: 100%;
    margin: 10px 0; /* Adjust spacing between items */
  }
  .navbar-row.collapsed {
    display: none;
  }
}

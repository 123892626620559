/* Footer CSS */
.footer-style {
  font-size: 10px;
  background: transparent;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative; /* Keep it relative */
  width: 100%;
}

.footer-content {
  width: 75%;
  margin: auto;
}

.modal-90w {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

.degreeModal{
    text-align: center;
    width:100%;
}

  


#gifexperience {
  position: absolute;
  width: 100%;
  height: 100vw;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGVxZW91b3FsaXR5M2wzZ2JpenZ1bjJ5NTd0ajJxbmdyeGI2bjllZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l5JbspfwZ0yjHjlJ0K/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  top: -10vw;
  left: 0;
  z-index: 0;
}

#gifeducation {
  position: absolute;
  width: 100%;
  height: 100vw;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExaXpjcG9qeGs1bnF0Z3Z5czl2aDg0dnh2OWFweWxqdmp6dWpyaWo3bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26BkLTlD4lUInspxe/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  top: -10vw;
  left: 0;
  z-index: 0;
}

#gifcontact {
  position: absolute;
  width: 100%;
  height: 100vw;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://i.giphy.com/l41YvpiA9uMWw5AMU.webp");
  background-repeat: no-repeat;
  background-size: cover;
  top: -10vw;
  left: 0;
  z-index: 0;
}

#gifprojects {
  position: absolute;
  width: 100%;
  height: 100vw;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExNTc2Z29tbGJic3c2NzVtYzFwMTFtcWhlcDgxYXR2b3NvN2gxdmUycSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o7ZeFcD0ZWsecY3bG/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  top: -10vw;
  left: 0;
  z-index: 0;
}

.skill-filter-modal {
  max-width: 90vw;
}

.skill-filter-modal .modal-content {
  background-color: grey;
}

.skill-filter-modal .modal-body {
  font-size: 0.7em;
}


@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.button-enter-active {
  animation: slideInFromLeft 0.5s forwards;
}

.button-exit-active {
  animation: slideOutToLeft 0.5s forwards;
}

.custom-button {
  border-color: white;
  color: white;
  background-color: transparent;
  margin-right: 10px;
  transition: all 0.4s;
}

.custom-button:hover {
  background-color: white;
  color: black;
}


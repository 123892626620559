.intro-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  z-index: 1000; /* High enough to cover navbar */
}

.intro-screen p {
  font-weight: 900;
}

.intro-screen h1 {
  text-align: center; /* Center align the text */
}

.youtube-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; /* Disable all interactions */
  z-index: 998; /* Below intro screen */
  background-color: black; /* Background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

#player {
  width: 300%;
  height: 100%;
  margin-left: -100%; /* Shift iframe to hide controls */
}

.skip-button {
  position: absolute;
  bottom: 100px;
  right: 100px;
  padding: 20px 60px; /* Adjust as needed for size */
  background-color: transparent;
  color: white;
  border: 2px solid white; /* White border */
  font-size: 40px; /* Adjust for larger text */
  cursor: pointer; /* Change cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */

  /* Large enough to be easily clicked or tapped */
  min-width: 100px;
  min-height: 50px;

  /* Additional styling for better visibility and interactivity */
  text-align: center;
  line-height: 50px; /* Adjust based on button height */
  font-weight: bold;
  border-radius: 5px; /* Rounded corners */
  z-index: 999;
}

.skip-button:hover,
.skip-button:focus {
  background-color: white; /* Change background on hover/focus */
  color: black; /* Change text color on hover/focus */
  outline: none; /* Remove outline on focus */
}

.intro-button {
  padding: 20px 60px; /* Adjust as needed for size */
  margin-top: 50px;
  background-color: transparent;
  color: white;
  border: 2px solid white; /* White border */
  font-size: 40px; /* Adjust for larger text */
  cursor: pointer; /* Change cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */

  /* Large enough to be easily clicked or tapped */
  min-width: 100px;
  min-height: 50px;

  /* Additional styling for better visibility and interactivity */
  text-align: center;
  line-height: 50px; /* Adjust based on button height */
  font-weight: bold;
  border-radius: 5px; /* Rounded corners */
}

.intro-button:hover,
.intro-button:focus {
  background-color: white; /* Change background on hover/focus */
  color: black; /* Change text color on hover/focus */
  outline: none; /* Remove outline on focus */
}

.intro1 {
  color: white;
  width: 28vw;
  height: 42vh;
  display: block;
  position: relative;
  top: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7.5vh;
  transition: all 5s;
  left: -65vw;
}
.intro1 img {
  width: 100%;
  height: 100%;
}
.main {
  color: white;
  width: 100%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.intro1.onload2 {
  left: 5vw;
}

.navLinks {
  color: white;
  text-decoration: none;
}

.navLinks:hover {
  color: rgb(108, 108, 243);
}

@media (orientation: portrait) {
  .youtube-container #player {
    object-fit: fit;
    width: 150%;
    height: 100%;
    margin-left: -25%; /* Shift iframe to hide controls */
    margin-top: 0%;
    transform: rotate(90deg);
  }

  .skip-button {
    transform: rotate(90deg);
    right: auto;
  }
}

/* Media query for screens with a max width of 800px */
@media (max-width: 800px) and (orientation: portrait) {
  .intro-screen h1 {
    /* Styles for screens narrower than 800px */
    font-size: smaller; /* Optional: Reduce the font size */
    white-space: normal; /* Allow text to wrap */
    line-height: 1.4; /* Adjust line height for better readability when wrapped */
  }
  .intro-screen p {
    font-size: smaller;
    white-space: normal;
    line-height: 1.4;
  }
  .intro-button {
    padding: 10px 30px; /* Adjust as needed for size */
  }
  .skip-button {
    bottom: 30px;
    right: auto;
    padding: 5px 15px;
    font-size: 30px; /* Adjust for larger text */
  }
}

/* Media query for screens with a max width of 800px */
@media (max-height: 800px) and (orientation: landscape) {
  .intro-screen h1 {
    /* Styles for screens narrower than 800px */
    font-size: smaller; /* Optional: Reduce the font size */
    white-space: normal; /* Allow text to wrap */
    line-height: 1.4; /* Adjust line height for better readability when wrapped */
  }
  .intro-screen p {
    font-size: smaller;
    white-space: normal;
    line-height: 1.4;
  }
  .intro-button {
    padding: 10px 30px; /* Adjust as needed for size */
  }
  .skip-button {
    bottom: 30px;
    right: auto;
    padding: 5px 15px;
    font-size: 30px; /* Adjust for larger text */
  }
}
